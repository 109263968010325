<template>
  <div>
    <nav
      :class="
        $route.name == 'About'
          ? 'my-navbar'
          : 'my-navbarWhite' &&
            ($route.name == 'jadidchilar_info' ? 'my-navbar' : 'my-navbarWhite')
      "
    >
      <div class="my-container d-flex justify-content-between">
        <div
          class="align-items-center justify-content-between float-left my-hamb"
          style="height : 100%;cursor:pointer"
        >
          <b-icon v-b-toggle.sidebar-1 icon="list" scale="2.5"></b-icon>
        </div>
        <div
          class="my-logo d-flex align-items-center justify-content-between"
          style="width : 250px !important"
        >
          <div>
            <!-- v-if="$route.name == 'About' || $route.name == 'jadidchilar_info'" -->
            <img
              class="navimage"
              src="@/assets/pictures/Emblem_of_Uzbekistan.svg"
              alt=""
            />
            <!-- <img
              v-if="$route.name != 'About' && $route.name != 'jadidchilar_info'"
              class="navimageWhite"
              src="@/assets/Emblem_of_Uzbekistan.svg"
              alt=""
            /> -->
          </div>
          <!-- :class="
              $route.name == 'About'
                ? 'ml-3 pt-3 textXTV'
                : 'ml-3 pt-3 textXTVBlack' &&
                  ($route.name == 'jadidchilar_info'
                    ? 'ml-3 pt-3 textXTV'
                    : 'ml-3 pt-3 textXTVBlack')
            " -->
          <div :class="'ml-3 pt-3 textXTV'">
            <p style="width:260px !important">
              {{ $t("XTV") }} <br />{{ $t("XTV2") }}
            </p>
          </div>
        </div>
        <div class="my-menu">
          <ul>
            <!-- href="javascript:history.go(-1)" -->
            <!-- <li @click="$router.push({ name: 'Home' })"> -->
            <li class="homeButtonMain">
              <a
                style="text-decoration: none !important;"
                class="homeButton"
                v-if="$route.name != 'About'"
                @click="$router.push({ name: 'About' })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.414"
                  height="10.828"
                  viewBox="0 0 6.414 10.828"
                  style="margin-right:5px"
                >
                  <path
                    id="arrow_icon"
                    data-name="arrow icon"
                    d="M0,0,4,4,8,0"
                    transform="translate(5 1.414) rotate(90)"
                    fill="none"
                    stroke="#ffff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>

                {{ $t("home") }}
              </a>
            </li>
            <!-- <li>
              <a
                :class="
                  $route.name == 'About'
                    ? 'atext'
                    : 'atextBlack' &&
                      ($route.name == 'jadidchilar_info'
                        ? 'atext'
                        : 'atextBlack')
                "
                @click="$router.push({ name: 'Regulations' })"
              >
                {{ $t("Regulations") }}
              </a>
            </li> -->
            <!-- <li>
              <a
                :class="
                  $route.name == 'About'
                    ? 'atext'
                    : 'atextBlack' &&
                      ($route.name == 'jadidchilar_info'
                        ? 'atext'
                        : 'atextBlack')
                "
                @click="$router.push({ name: 'school' })"
              >
                {{ $t("school") }}
              </a>
            </li> -->
            <!-- <li>
              <a> Колледж </a>
            </li> -->
            <!-- <li>
              <a> Университет </a>
            </li> -->

            <!-- <li class="dropdown">
              <a
                :class="
                  $route.name == 'About'
                    ? 'atext'
                    : 'atextBlack' &&
                      ($route.name == 'jadidchilar_info'
                        ? 'atext'
                        : 'atextBlack')
                "
              >
                {{ $t("СЕРВИСЫ") }}
                <svg
                  v-if="
                    $route.name == 'About' || $route.name == 'jadidchilar_info'
                  "
                  class=" iconBlack ml-1 mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.828"
                  height="6.414"
                  viewBox="0 0 10.828 6.414"
                >
                  <path
                    id="arrow_icon"
                    data-name="arrow icon"
                    d="M0,0,4,4,8,0"
                    transform="translate(1.414 1.414)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>

                <svg
                  class=" iconBlack ml-1 mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.828"
                  height="6.414"
                  viewBox="0 0 10.828 6.414"
                >
                  <path
                    id="arrow_icon"
                    data-name="arrow icon"
                    d="M0,0,4,4,8,0"
                    transform="translate(1.414 1.414)"
                    fill="none"
                    stroke="#041429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </a>

              <div class="dropdown-content">
                <div>
                  <a
                    :class="{ active1: $route.name === 'Qabul' }"
                    href="#"
                    @click="$router.push({ name: 'Qabul' })"
                  >
                    {{ $t("Qa'bul") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'ota_onalar_tulovi' }"
                    @click="$router.push({ name: 'ota_onalar_tulovi' })"
                  >
                    {{ $t("Ота_оналар_тўлови") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{
                      active1: $route.name === 'Oqishni-kochirish-va-tiklash',
                    }"
                    @click="
                      $router.push({ name: 'Oqishni-kochirish-va-tiklash' })
                    "
                    >{{ $t("O'qishni ko'chirish va tiklash") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'Ijtimoiy-Soha' }"
                    @click="$router.push({ name: 'Ijtimoiy-Soha' })"
                    >{{ $t("Ijtimoiy_Soha") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'Rasmiy-hujjatlar' }"
                    @click="$router.push({ name: 'Rasmiy-hujjatlar' })"
                  >
                    {{ $t("Rasmiy_hujjatlar") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{
                      active1: $route.name === 'https://oylik.uzedu.uz/#/',
                    }"
                    href="https://oylik.uzedu.uz/#/"
                    target="_blank"
                    >{{ $t("Calculator") }}</a
                  >
                </div>
              </div>
            </li> -->

            <li class="dropdown">
              <!-- :class="
                  $route.name == 'About'
                    ? 'atext'
                    : 'atextBlack' &&
                      ($route.name == 'jadidchilar_info'
                        ? 'atext'
                        : 'atextBlack')
                " -->
              <a :class="'atext'">
                {{ $t("СПРАВОЧНИКИ") }}
                <!-- <svg
                  v-if="
                    $route.name == 'About' || $route.name == 'jadidchilar_info'
                  "
                  class=" iconBlack ml-1 mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.828"
                  height="6.414"
                  viewBox="0 0 10.828 6.414"
                >
                  <path
                    id="arrow_icon"
                    data-name="arrow icon"
                    d="M0,0,4,4,8,0"
                    transform="translate(1.414 1.414)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg> -->
                <!-- v-if="$route.name != 'About'" -->
                <svg
                  class=" iconBlack ml-1 mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.828"
                  height="6.414"
                  viewBox="0 0 10.828 6.414"
                >
                  <path
                    id="arrow_icon"
                    data-name="arrow icon"
                    d="M0,0,4,4,8,0"
                    transform="translate(1.414 1.414)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </a>

              <div class="dropdown-content" style="z-index: 3 !important;">
                <!-- <div>
                  <a
                    :class="{ active1: $route.name === 'jadidchilar_info' }"
                    @click="$router.push({ name: 'jadidchilar_info' })"
                  >
                    {{ $t("famousPeople") }}</a
                  >
                </div> -->
                <div>
                  <a
                    :class="{ active1: $route.name === 'savol_javob' }"
                    @click="$router.push({ name: 'savol_javob' })"
                  >
                    {{ $t("answerandquestion") }}</a
                  >
                </div>

                <div>
                  <a
                    :class="{ active1: $route.name === 'infoPupil' }"
                    @click="$router.push({ name: 'infoPupil' })"
                    >{{ $t("infoGraphic") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'school' }"
                    @click="$router.push({ name: 'school' })"
                  >
                    {{ $t("school") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'imtiyozlar' }"
                    @click="$router.push({ name: 'imtiyozlar' })"
                  >
                    {{ $t("imtiyoz") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'CalculationKind' }"
                    @click="$router.push({ name: 'CalculationKind' })"
                  >
                    {{ $t("CalculationKind") }}</a
                  >
                </div>
                <div>
                  <a
                    :class="{ active1: $route.name === 'taklif' }"
                    @click="$router.push({ name: 'taklif' })"
                  >
                    {{ $t("write") }}</a
                  >
                </div>
                 <div>
                  <a
                    :class="{ active1: $route.name === 'Instruction' }"
                    @click="$router.push({ name: 'Instruction' })"
                  >
                    {{ $t("Instruction") }}</a
                  >
                </div>
                 <div>
                  <a
                    :class="{ active1: $route.name === 'Qualification' }"
                    @click="$router.push({ name: 'Qualification' })"
                  >
                    {{ $t("Qualification") }}</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="my-language">
          <b-dropdown
            class="my-dropdown d-inline-block"
            size="lg"
            split-variant="info"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
          >
            <template #button-content>
              <!-- $route.name == 'About'
                    ? 'atext'
                    : 'atextBlack' &&
                      ($route.name == 'jadidchilar_info'
                        ? 'atext'
                        : 'atextBlack') -->
              <!-- <b-button :class="'atext'" variant="inherit"
                ><b-icon scale="0.8" icon="globe"></b-icon>
                {{ $t("lang") }}

                <b-icon scale="0.6" icon="chevron-down"></b-icon>
              </b-button> -->
            </template>

            <b-dropdown-item
              @click="changeLanguage('uz_latn')"
              :class="{ active: lang === 'uz_latn' }"
            >
              O'zbek tili
            </b-dropdown-item>
            <b-dropdown-item
              @click="changeLanguage('uz_cyrl')"
              :class="{ active: lang === 'uz_cyrl' }"
            >
              Ўзбек тили</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeLanguage('ru')"
              :class="{ active: lang === 'ru' }"
            >
              Русский язык
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-sidebar
        style="z-index: 4 !important;"
        backdrop
        id="sidebar-1"
        title="Menu"
        shadow
      >
        <b-list-group>
          <b-list-group-item
            @click="$router.push({ name: 'Home' })"
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="home" class="mr-2"> </b-icon> {{ $t("home") }}
            </span>
            <!-- <b-badge variant="primary" pill>14</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>
          <!-- <b-list-group-item
            @click="$router.push({ name: 'Regulations' })"
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="home" class="mr-2"> </b-icon>
              {{ $t("Regulations") }}
            </span>
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item> -->

          <!-- <b-list-group-item
            @click="$router.push({ name: 'school' })"
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="home" class="mr-2"> </b-icon> {{ $t("school") }}
            </span>
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item> -->

          <!-- <div style="margin-right:3px;margin-left:2px">
            <b-button
              class="wewe"
              style="width:100%;background-color:white;color:black;min-height:50px;border: 1px solid #F3F3F3"
              v-b-toggle.collapse-3
              ><span>
                <b-icon icon="home" class="mr-2"> </b-icon> {{ $t("СЕРВИСЫ") }}
              </span>

              <b-icon
                style="margin-left:50%"
                class="iconlocation1"
                icon="chevron-right"
              ></b-icon>
            </b-button>
            <b-collapse id="collapse-3">
              <b-card @click="$router.push({ name: 'Qabul' })">
                {{ $t("Qa'bul") }}</b-card
              >
              <b-card @click="$router.push({ name: 'ota_onalar_tulovi' })">
                {{ $t("Ота_оналар_тўлови") }}</b-card
              >
              <b-card
                @click="$router.push({ name: 'Oqishni-kochirish-va-tiklash' })"
              >
                {{ $t("O'qishni ko'chirish va tiklash") }}</b-card
              >
              <b-card @click="$router.push({ name: 'Ijtimoiy-Soha' })">
                {{ $t("Ijtimoiy_Soha") }}</b-card
              >
              <b-card @click="$router.push({ name: 'Rasmiy-hujjatlar' })">
                {{ $t("Rasmiy_hujjatlar") }}</b-card
              >
              <a
                href="https://oylik.uzedu.uz/#/"
                target="_blank"
                style="color:black"
              >
                <b-card> {{ $t("Calculator") }}</b-card>
              </a>
            </b-collapse>
          </div> -->
          <div style="margin-right:1px;margin-left:2px">
            <b-button
              class="wewe"
              style="width:100%;background-color:white;color:black;min-height:50px;border: 1px solid #F3F3F3"
              v-b-toggle.collapse-4
              ><span>
                <b-icon icon="home" class="mr-2"> </b-icon>
                {{ $t("СПРАВОЧНИКИ") }}
              </span>

              <b-icon
                style="margin-left:17%"
                class="iconlocation"
                icon="chevron-right"
              ></b-icon>
            </b-button>
            <b-collapse id="collapse-4">
              <!-- <b-card @click="$router.push({ name: 'jadidchilar_info' })">
                {{ $t("Mashhur arboblar") }}</b-card
              > -->
              <b-card @click="$router.push({ name: 'savol_javob' })">
                {{ $t("answerandquestion") }}</b-card
              >
              <b-card @click="$router.push({ name: 'infoPupil' })">
                {{ $t("infoGraphic") }}</b-card
              >

              <b-card @click="$router.push({ name: 'school' })">
                {{ $t("school") }}</b-card
              >
              <b-card @click="$router.push({ name: 'imtiyozlar' })">
                {{ $t("imtiyoz") }}</b-card
              >
              <b-card @click="$router.push({ name: 'CalculationKind' })">
                {{ $t("CalculationKind") }}</b-card
              >
              <b-card @click="$router.push({ name: 'taklif' })">
                {{ $t("write") }}</b-card
              >
              <b-card @click="$router.push({ name: 'Instruction' })">
                {{ $t("Instruction") }}</b-card
              >
              <b-card @click="$router.push({ name: 'Qualification' })">
                {{ $t("Qualification") }}</b-card
              >
            </b-collapse>
          </div>
        </b-list-group>
      </b-sidebar>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpenDropdown: false,
      lang: "",
      name: "",
    };
  },
  created() {
    this.lang = localStorage.getItem("lang") || 'uz_cyrl';
  },

  methods: {
    close() {
      this.isOpenDropdown = false;
    },
    changeLanguage(lang) {
      this.lang = lang;
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style>
.wewe {
  text-align: left !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: 10px;
  display: none;
  right: 0;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 10px;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
