<template>
  <b-overlay>
   
    <my-navbar></my-navbar>
    <div class="my-content">
      <transition  name="fade">
        <router-view ></router-view>
      </transition>
    </div>
    <my-footer></my-footer>
    <!-- <b-navbar toggleable="lg" type="dark" variant="primary">
            <b-container>
                <b-navbar-brand  href="#"> EDU CONSULT </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">

                    <b-nav-item-dropdown text="O'zbekcha" class="lang-dropdown" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
        <div>
            <router-view></router-view>
        </div>
        <div class="footer">
        </div> -->
  </b-overlay>
</template>

<script>
import myNavbar from "../layout/navbar";
import myFooter from "../layout/footer";
export default {
  components: { myNavbar, myFooter },
};
</script>

<style>
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
